<template>
  <div class="d-flex flex-column justify-content-end">
    <div class="">
      <div class="quizell-ai-title" style="max-width: 65%; margin-bottom: 25px">
        What specific information fields would you loke to include for capturing
        leads?
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-3">
        <div
          class="card"
          style="
            border-radius: 12px;
            width: 230px;
            border: 1px solid rgba(24, 25, 28, 0.08);
            background: rgb(249, 249, 249);
          "
        >
          <div class="card-body info">
            <div class="d-flex justify-content-between align-items-center">
              <span>E-mail</span>
              <b-form-checkbox
                v-model="QuizPayload.lead_page_fields.email"
                size="lg"
                class="checkbox"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3">
        <div
          class="card"
          style="
            border-radius: 12px;
            width: 230px;
            border: 1px solid rgba(24, 25, 28, 0.08);
            background: rgb(249, 249, 249);
          "
        >
          <div class="card-body info">
            <div class="d-flex justify-content-between align-items-center">
              <span>Full Name</span>
              <b-form-checkbox
                v-model="QuizPayload.lead_page_fields.full_name"
                size="lg"
                class="checkbox"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3">
        <div
          class="card"
          style="
            border-radius: 12px;
            width: 230px;
            border: 1px solid rgba(24, 25, 28, 0.08);
            background: rgb(249, 249, 249);
          "
        >
          <div class="card-body info">
            <div class="d-flex justify-content-between align-items-center">
              <span>Phone Number</span>
              <b-form-checkbox
                v-model="QuizPayload.lead_page_fields.phone_number"
                size="lg"
                class="checkbox"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div
          class="card"
          style="
            border-radius: 12px;
            width: 230px;
            border: 1px solid rgba(24, 25, 28, 0.08);
            background: rgb(249, 249, 249);
          "
        >
          <div class="card-body info">
            <div class="d-flex justify-content-between align-items-center">
              <span>Terms & Conditions</span>
              <b-form-checkbox
                v-model="QuizPayload.lead_page_fields.terms_and_service"
                size="lg"
                class="checkbox"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-collapse
      id="collapse-4"
      v-model="QuizPayload.lead_page_fields.terms_and_service"
    >
      <div
        class="card mt-3"
        :class="[
          !QuizPayload.lead_page_fields.terms_and_service ? 'disabled' : '',
        ]"
        style="border-radius: 12px; border-color: rgba(24, 25, 28, 0.08)"
      >
        <div class="card-body" style="padding: 16px">
          <div class="d-flex justify-content-center align-items-center">
            <div class="">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 16H17C19.2091 16 21 14.2091 21 12C21 9.79086 19.2091 8 17 8H15M8 12H16M9 8H7C4.79086 8 3 9.79086 3 12C3 14.2091 4.79086 16 7 16H9"
                  stroke="#4D1B7E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="" style="flex: 1">
              <input
                type="text"
                name=""
                :disabled="!QuizPayload.lead_page_fields.terms_and_service"
                v-model="tncLink"
                class="w-100 importQuizTextArea"
                placeholder="Please share the link to your terms and conditions here (https)"
                id=""
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <!-- <div class="mt-2" style="box-shadow: 0px -2px 20px 0px rgba(24, 25, 28, 0.08);"></div> -->
    <div class="row box-with-shadow mt-3">
      <div class="col-12 d-flex justify-content-end">
        <Button
          @click="$emit('click')"
          class="mr-2"
          style="background: #b3afb6; min-width: 20px"
          >Skip
        </Button>
        <Button @click="$emit('click')" :disabled="!isTNCLinkProvided"
          >Next Step
          <span class="mr-1"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M3.33334 9.16669V10.8334H13.3333L8.75001 15.4167L9.93334 16.6L16.5333 10L9.93334 3.40002L8.75001 4.58336L13.3333 9.16669H3.33334Z"
                fill="white"
              /></svg
          ></span>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "../../Layout/Button.vue";
export default {
  components: {
    Button,
  },
  props: {
    QuizPayload: Object,
  },
  watch: {
    text(newValue) {
      this.$emit("input", newValue);
    },
  },
  data() {
    return {
      text: "",
    };
  },
  computed: {

    tncLink:{
      get() {
        return this.QuizPayload.lead_page_fields.terms_and_service_link;
      },
      set(value) {
        this.QuizPayload.lead_page_fields.terms_and_service_link = value;
        this.$store.dispatch('formatUrl', value).then((formattedUrl) => {
          this.QuizPayload.lead_page_fields.terms_and_service_link = formattedUrl;
        });
      }
    },

    isTNCLinkProvided() {
      if (this.QuizPayload.lead_page_fields.terms_and_service) {
        let pattern = /^((http|https|ftp):\/\/)/;
        return (
          this.QuizPayload.lead_page_fields.terms_and_service_link.trim() !==
            "" &&
          pattern.test(this.QuizPayload.lead_page_fields.terms_and_service_link)
        );
      }
      return true;
    },
  },
};
</script>
<style scoped>
.disabled {
  background-color: #f2f2f2;
  color: #999;
  cursor: not-allowed;
  pointer-events: none;
}

.box-with-shadow {
  position: relative;
}
.card-body div span {
  color: #73738d;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: 0.64px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4d1b7e;
  background-color: #4d1b7e !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #4d1b7e;
}
.importQuizTextArea {
  border: none;
  outline: none;
  resize: none;
}

.importQuizTextArea:active {
  border: none;
  outline: none;
}

.info {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.quizell-ai-info {
  color: #1f1f28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.56px;
}

.quizell-ai-title {
  color: #1f1f28;

  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: 0.88;
}
</style>
